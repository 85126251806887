.article-teaser {
  padding: 2rem 0 4rem 0;

  @include bp(lg) {
    max-width: 920px;
    margin: 0 auto;
  }

  &__title {
    font-size: rem(35);
    font-weight: 300;
    color: $blue;
  }

  &__article {
    position: relative;
  }

  .article__info {
    justify-content: flex-start;
    margin-top: 1rem;

    > li + li {
      margin-left: 38px;
    }

    @include bp(sm) {
      margin-bottom: 1rem;
    }
  }

  .article__tag {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 99;
  }
}
