.related-news {
  background: #fafafa;
  margin-bottom: -30px;
}

.articles-list {
  padding: 1rem 0 !important;

  &__filters {

    margin: 1rem 15px 2rem 15px;

    .filter-order {
      margin-left: auto;
      color: $light-blue;
      text-decoration: none;
      text-transform: uppercase;
      font-size: rem(12);
    }

    @include bp(lg) {

      display: flex;
      align-items: center;
    }
  }

  .tag-list {
    width: 100%;
    padding-bottom: 1rem;
    overflow-x: auto;

    @include bp(lg) {
      width: auto;
      padding-bottom: 0;
    }
  }

  .swiper-slide {
    width: 85vw;

    @include bp(sm) {
      width: auto;
    }
  }

  &__items {
    list-style: none;
    padding: 0 15px;

    &.swiper-wrapper {
      padding: 0;
    }

    @include bp(sm) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__item {
    height: auto !important;
    margin-bottom: 2rem;

    @include bp(sm) {
      flex: 0 0 percentage(1/3);
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 2rem;
    }
  }

  .article {
    position: relative;
    border-radius: 10px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
    //overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .article__img {
    display: block;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .article__tag {
    position: absolute;
    top: 15px;
    left: 15px;
  }

  .article__txt {
    padding: 1rem 26px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .article__title {
    color: $blue;
    font-size: rem(20);
    margin-bottom: 0.5rem;
  }

  .article__abstract {
    font-size: rem(14);
    line-height: 1.6;
    margin-bottom: 20px;
  }

  .article__info {
    margin-top: auto;
    justify-content: space-between;
  }

  .related-news & {
    transform: translateY(-80px);
  }

  .swiper-container {
    padding-bottom: 1rem !important;
  }

  .download-menu {
    list-style: none;
    position: absolute;
    left: 0;
    top: calc(100% + 10px);
    border-radius: 10px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    background: #ffffff;
    padding: 1rem 20px;
    z-index: 999;
    display: none;

    > li {
      padding: .4rem 0;
    }

    a {
      font-size: rem(14);
      font-weight: 500;
      text-transform: uppercase;
      text-decoration: none;
      color: $light-blue;
    }
  }

  .contextual-open {
    .download-menu {
      display: block;
    }

    .button--download {
      background: #005bcb;
    }
  }

  &--apps {
  
    .articles-list__items {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
