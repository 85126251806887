.hero {
  position: relative;

  &__img {
    img {
      width: 100%;
    }
  }

  &__txt {
    color: #005bcb;
    padding-top: 24px;
    position: absolute;
    top: 0;
    width: 100%;

    @include bp(sm) {

    }
  }

  &__message {
    max-width: 445px;
    font-size: rem(16);
    line-height: 1.75;
    color: $text-color;
  }

  .page-title {
    margin-bottom: 1rem;
    font-size: rem(40);

    @include bp(sm) {
      margin-bottom: 1.5rem;
      font-size: rem(75);
    }
  }

  .page-subtitle {
    font-weight: normal;
    font-size: rem(28);

    @include bp(sm) {
      font-size: rem(35);
    }
  }

  .button-wrapper {
    margin-top: 100px;

    @include bp(sm) {
      margin-top: 8rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .button {
        margin-right: 1rem;
      }
    }

    .button {
      @include bp-max(sm) {
        width: auto;
        padding: 0 20px;

        & + .button {
          margin-top: 20px;
        }
      }
    }
  }

  & + .overflow-top {
    transform: translateY(-50%);
  }

  &--hp {
    .hero__txt {
      @include bp-max(xs) {
        position: relative;
        margin-top: -40%;
      }
    }

    .button-wrapper {
      margin-top: 1rem;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      @include bp(sm) {
        margin-top: 3rem;
        flex-direction: row;
      }
    }

    & + .overflow-top {
      @include bp-max(xs) {
        transform: none;
        margin-top: 5rem;
      }
    }
  }
}
