.btn-gotop {
  border: none;
  background: $light-blue;
  border-radius: 50%;
  @include square(36px);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    fill: #fff;
  }
}

.ui-button {
  @include square(32px);
  border: 0;
  background: none;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .icon-close {
    display: none;
  }

  &.active {
    .icon-open {
      display: none;
    }

    .icon-close {
      display: block;
    }
  }

  svg {
    @include square(20px);
    fill: $dark-blue;
  }

  &--search {
    margin-left: auto;

    &.active {
      @include bp(lg) {
        background: #fff;
      }
    }
  }

  &--nav {
    margin-left: 40px;

    svg {
      @include square(24px);
    }
  }
}

.button-wrapper {
  display: flex;
  justify-content: center;

  @include bp(sm) {
    display: block;
  }

  &--center {
    display: flex !important;
  }

  &--inline {
    display: block;

    @include bp(sm) {
      display: flex;
      justify-content: flex-start;
    }

    .button + .button {
      @include bp(sm) {
        margin-left: 2.5rem;
      }
    }
  }

  &--contextualmenu {
    position: relative;
  }
}

.button {
  display: inline-block;
  border-radius: 24px;
  line-height: 48px;
  text-transform: uppercase;
  font-size: rem(14);
  font-weight: 500;
  letter-spacing: 1.25px;
  text-decoration: none;
  width: 100%;
  text-align: center;

  @include bp(sm) {
    width: auto;
    padding: 0 rem(35);
  }

  &--blue {
    color: #fff;
    background: $light-blue;
  }

  &--transparent {
    border: 1px solid #fff;
    color: #fff;
  }

  &--white {
    background: #fff;
    color: $light-blue;
  }

  &--light-blue {
    color: $light-blue;
    border: 1px solid $light-blue;
  }

  &--play {
    display: inline-flex;
    align-items: center;

    .button__icon {
      margin-right: 0.8rem;
    }

    &--inverted {
      color: $light-blue;
      padding: 0;

      .button__icon {
        margin-right: 0;
        margin-left: 0.8rem;
      }
    }
  }

  &--download {
    display: inline-flex;
    align-items: center;

    &::before {
      content: '';
      @include square(8px);
      border-left: 2px solid #fff;
      border-bottom: 2px solid #fff;
      transform: rotate(-45deg);
      margin-left: -10px;
      margin-right: 1rem;
    }

    &:hover, &.active {
      background: #005bcb;
    }
  }

  &--store {
    display: flex;
    align-items: center;
    padding: 0;
    color: $light-blue;

    svg {
      fill : $light-blue;
      width: 15px;
      height: 18px;
      margin-right: 15px;
    }
  }

  &--share-fb {
    display: inline-flex;
    align-items: center;
    background: $light-blue;
    color: #fff;
    width: auto;
    padding: 0 24px;

    .button__icon {
      @include square(20px);
      fill: #fff;
      margin-right: 1rem;
    }
  }
}

.load-more {
  background: none;
  border: none;
  color: $light-blue;
  font-size: rem(14);
  text-transform: uppercase;
  font-weight: 500;

  svg {
    margin-left: 10px;
    fill: $light-blue;
  }
}

.button--modifica {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  font-size: rem(14);
  font-weight: 500;
  color: $light-blue;
  text-transform: uppercase;
  cursor: pointer;

  .button__label {
    margin-right: 10px;
  }
}
