.page-title {
  font-size: rem(42);
}

.section-title {
  font-size: rem(34);
  line-height: rem(44);
  margin-bottom: 1rem;
}

.text--xsmall {
  font-size: rem(12);
}

.text--small {
  font-size: rem(14);
}

.text--medium {
  font-size: rem(18);
  line-height: rem(24);

  @include bp(xl) {
    font-size: rem(21);
    line-height: rem(28);
  }
}
