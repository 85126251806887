.image-text {
  padding: 30px 15px;

  @include bp(sm) {
    padding: 60px 0;
  }

  > .container {
    padding-left: 0;
    padding-right: 0;
  }

  &__row {
    display: flex;
    flex-direction: column;

    @include bp(sm) {
      flex-direction: row;
    }

    > *:first-child {
      @include bp(sm) {
        margin-right: 40px;
      }

      @include bp(lg) {
        margin-right: 95px;
      }
    }

    & + .image-text__row {
      margin-top: 70px;

      @include bp(sm) {
        margin-top: 120px;
      }
    }

    &:last-child {
      margin-bottom: 40px;
    }
  }

  &__txt {
    padding: 0 15px;

    @include bp(sm) {
      padding: 0;
    }

    @include bp-max(xs) {
      order: 2 !important;
    }

    margin-top: 30px;
    font-size: rem(16);

    h3 {
      font-size: rem(35);
      color: $blue;
      font-weight: 300;
      margin-bottom: 20px;

      @include bp(sm) {
        font-size: rem(40);
      }

      @include bp(lg) {
        font-size: rem(49);
      }
    }

    p {
      line-height: 1.75;

      @include bp(sm) {
        max-width: 475px;
      }

      + p {
        margin-top: 2rem;
      }
    }

    .highlight {
      color: $blue;
      font-size: rem(26);
      line-height: 1.2;
    }

    .button-wrapper {
      margin-top: 40px;
    }
  }

  &__img {
    flex-shrink: 0;
    
    @include bp(sm) {
      max-width: 50%;
      margin-left: auto;
    }

    @include bp-max(xs) {
      order: 1 !important;
    }

    img {
      display: block;
      border-radius: 20px;
      width: 100%;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    }
  }

  .img--noRadius {
    img {
      border-radius: 0;
    }
  }

  &--overflow {
    max-width: none;

    @include bp-max(xs) {
      padding-left: 0;
      padding-right: 0;
    }

    @include bp(sm) {
      padding-bottom: 0;
    }

    .image-text__row {
      align-items: center;
      margin-top: 0;

      > :first-child {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .image-text__txt {
          @include bp(sm) {
            padding-left: 0;
          }
        }
      }

      > * {
        flex: 1 0 50%;
        margin-right: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .image-text__txtCol {
      @include bp-max(xs) {
        order: 2 !important;
      }
    }

    .image-text__img {
      margin-right: 0;
      margin-left: 0;
    }

    .image-text__txt {
      @include bp(sm) {
        max-width: 440px;
        padding: 3vw 5vw;
      }

      @include bp(lg) {
        max-width: 505px;
      }

      @include bp(xl) {
        max-width: 575px;
      }
    }
  }
}
