.product-list {
  padding: 10px 0;

  @include bp(sm) {
    padding: 60px 0;
  }

  &__items {
    list-style: none;

    @include bp(sm) {
      display: flex;
      display: flex;
      flex-wrap: wrap;
    }

    > li {
      flex-basis: percentage(1/4);
      padding: 15px;
    }

    .product {
      background: #fff;
      min-height: 68px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
      font-size: rem(20);
      font-weight: 500;
      text-decoration: none;
      color: $blue;
      padding: 0 1rem;
      border-radius: 10px;
    }
  }

  .button-wrapper {
    margin: 60px 0 30px 0;
  }
}
