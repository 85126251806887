.faq {
  position: relative;
  background: #fafafa;
  padding: 3rem 0;

  .section-title {
    color: $blue;
    font-weight: normal;
    font-size: rem(28);

    @include bp(sm) {
      font-size: rem(35);
    }
  }

  .tabbed--faq {
    display: flex;

    @include bp(sm) {
      margin-top: 3rem;

      > * {
        flex: 0 0 50%;
      }
    }

    &__content {
      padding-right: 1rem;

      @include bp(sm) {
        padding-right: 0;
      }
    }
  }

  &__bottom {
    margin-top: 3rem;

    h5 {
      color: $blue;
      font-size: rem(20);
      margin-bottom: 1rem;
    }

    .button {
      width: auto;
      padding: 0 20px;
      margin-bottom: 1rem;
    }
  }
}
