.banner {
  padding-top: 46px;
  padding-bottom: 120px;

  .container {
    @include bp(sm) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .button {
    flex-shrink: 0;
  }

  &__title {
    color: #fff;
    font-weight: 300;
    font-size: rem(28);
    line-height: 1.3;
    margin-bottom: 30px;

    @include bp(sm) {
      font-size: rem(35);
      line-height: 1.7;
      margin-bottom: 0;
    }
  }

  &--blue {
    background: $blue;
  }

  &--supporto {
    background: #fafafa;
    padding-top: 60px;
    padding-bottom: 70px;

    .banner__col {
      flex: 1 0 50%;

      @include bp-max(xs) {
        + .banner__col {
          margin-top: 2rem;
        }
      }
    }

    .banner__title {
      color: $blue;
      font-size: rem(20);
      font-weight: 500;

      @include bp-max(xs) {
        margin-bottom: 1rem;
      }
    }

    .button {
      width: auto;
      padding: 0 2rem;

      @include bp(sm) {
        margin-top: 25px;
      }
    }
  }

  &--contact-us {
    background: url(../images/layout/contacts-bg.png) 50% 50% no-repeat;
    background-size: cover;
    padding-bottom: 70px;

    .container {
      @include bp(sm) {
        flex-direction: column;
      }
    }

    .banner__title {
      font-size: rem(35);
      text-align: center;

      @include bp(sm) {
        font-size: rem(50);
        margin-bottom: 40px;
      }
    }
  }

  &--white {
    background: #fff;
    padding-bottom: 70px;

    .container {
      @include bp(sm) {
        flex-direction: column;
      }
    }

    .banner__title {
      font-size: rem(35);
      color: $light-blue;
      text-align: center;

      @include bp(sm) {
        font-size: rem(50);
        margin-bottom: 40px;
      }
    }
  }
}
