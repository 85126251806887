.form-search {
  background: $blue;
  position: fixed;
  top: 57px;
  left: 0;
  width: 100vw;
  padding: 15px;
  z-index: 999;
  display: none;

  .search-open & {
    display: block;
  }

  @include bp(sm) {
    top: 80px;
    padding: 25px 40px;
  }

  @include bp(lg) {
    display: block;
    position: static;
    width: 218px;
    padding: 0;
    background: transparent;
  }

  .form-keyword {
    display: flex;
    align-items: center;
    padding: 0 25px;
    border: 1px solid $blue;
    border-radius: 24px;
    background: #fff;

    @include bp(lg) {
      border-radius: 15px;
      padding: 0 10px;
    }

    .search-icon {
      height: 1rem;
    }
  }

  input[type="text"] {
    border: 0;
    background: none;
    flex-grow: 999;
    line-height: 28px;
    height: 48px;
    font-family: $font-rubik;
    font-size: rem(12);
    margin-right: 1rem;
    min-width: 0%;

    @include input-placeholder {
      color: $light-grey;
      font-size: rem(14);
      font-weight: 500;
      text-transform: uppercase;
    }

    @include bp(lg) {
      height: 24px;
      line-height: 24px;
    }
  }

  @include bp-max(xs) {
    position: fixed;
  }
}

/*
 * Risultati di Ricerca
 */
.search-page {

  &__header {
    padding-bottom: 120px;

    .container {
      padding-left: 15px;
      padding-right: 15px;
    }

    .header-title {
      color: $dark-blue;
      font-size: rem(20);
    }

    .search-keyword {
      color: $blue;
      font-size: rem(75);
      display: inline-block;
      padding-right: 1rem;
      border-right: 1px solid $blue;
    }
  }

  &__results {
    background: #fafafa;
    padding-bottom: 3rem;

    .container {
      position: relative;

      @include bp(sm) {
        margin-top: -2vh;
        max-width: 730px;
      }
    }

    .search-count {
      font-weight: 500;
      color: $light-blue;
      font-size: rem(14);
      margin-bottom: 1rem;
      text-transform: uppercase;
      letter-spacing: 1.25px;

      > span {
        color: #aeaeae;
      }
    }

    &--notfound {
      background: none;
      padding-bottom: 10rem;

      .container {
        margin-top: 0 !important;
      }
    }
  }

  &__message {
    font-size: rem(14);
    line-height: 1.6;

    strong {
      color: $blue;
      font-size: rem(20);
    }
  }
}

.search-results__items {
  list-style: none;

  .search-result {
    background: #fff;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 24px 39px 24px 33px;
    border-radius: 10px;

    &__title {
      display: block;
      color: $blue;
      font-size: rem(20);
      margin-bottom: 10px;
    }

    &__abstract {
      display: block;
      font-size: rem(14);
      line-height: rem(22);
    }

    .button-wrapper {
      margin-top: 1.5rem;
    }

    + .search-result {
      margin-top: 1.5rem;
    }
  }
}
