.form-alfaevolution {
  .form-item {
    position: relative;
    margin: 2rem 0;

    label {
      display: block;
      color: #151515;
      opacity: .5;
      font-size: rem(20);
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
      transform-origin: left top;
      cursor: text;

      &.floating {
        transform: translateY(-115%) scale(0.65);
        opacity: 1;
      }
    }

    &--textfield, &--textarea {
      label {
        position: absolute;
        left: 0;
        margin-left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &--textarea {
      label {
        top: 20%;

        &.floating {
          transform: translateY(-150%) scale(0.65);
        }
      }
    }

    &--select {
      label {
        font-size: rem(13);
        opacity: 1;
        margin-bottom: 5px;
      }
    }

    input[type="text"], input[type="email"] {
      background: none;
      border: none;
      border-bottom: 1px solid #e9e9e8;
      width: 100%;
      padding: 20px 16px 6px 0;
      outline: none;
      font-family: inherit;
      font-size: rem(20);
      transition: all cubic-bezier(0.4, 0, 0.2, 1);

      &:focus {
        border-color: #000;
      }
    }

    textarea {
      padding: 15px 0;
      background: none;
      border: none;
      border-bottom: 1px solid #e9e9e8;
      width: 100%;
      height: 120px;
      outline: none;
      resize: none;
    }

    select {
      appearance: none;
      background-color: transparent;
      border: none;
      padding: 0;
      margin: 0;
      width: 100%;
      height: 40px;
      font-family: inherit;
      font-size: rem(20);
      cursor: inherit;
      line-height: inherit;
      outline: none;
      background: url('../images/layout/select-arrow.svg') 100% 10px no-repeat;
      border-bottom: 1px solid #e9e9e8;

      &:focus {
        border-color: #000;
      }

      &::-ms-expand {
        display: none;
      }
    }
  }

  [type="checkbox"]:checked,
  [type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="checkbox"]:checked+label,
  [type="checkbox"]:not(:checked)+label {
    position: relative;
    padding-left: 30px;
    font-size: rem(12);
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    opacity: 1 !important;
  }

  [type="checkbox"]:checked+label:before,
  [type="checkbox"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 7px;
    border: 1px solid #979797;
  }

  [type="checkbox"]:checked+label:after {
    content: '';
    @include square(20px);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 7px;
    border: 1px solid $light-blue;
    background: $light-blue url(../images/layout/checkbox.svg) 50% 50% no-repeat;
    background-size: 10px;
    opacity: 1;
  }

  input[type="submit"] {
    border: none;
    min-width: 220px;
  }

  .button--submit {
    border: 0;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;

    .button__icon {
      margin-right: 10px;
    }
  }
}

.form-contatti {

  &__title {
    color: $blue;
    font-size: rem(28);
    font-weight: normal;
    margin-bottom: 1rem;

    @include bp(sm) {
      font-size: rem(35);
    }
  }

  &__intro {
    line-height: 1.75;
  }
}
