// Blur i.e = @include blur(2)
@mixin blur($b:5) {
  -webkit-filter: blur(#{$b}px);
  -moz-filter: blur(#{$b}px);
  -o-filter: blur(#{$b}px);
  -ms-filter: blur(#{$b}px);
  filter: url("data:image/svg+xml;utf8,<svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\"><filter id=\"blur\"><feGaussianBlur in=\"SourceGraphic\" stdDeviation=\"#{$b}\" /></filter></svg>#blur");

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}

// CLEARFIX
@mixin clearfix {
  zoom: 1;
  &:before { content: ''; display: block; }
  &:after { content: ''; display: table; clear: both; }
}

// Opacity
@mixin opacity($value){
  $IEValue: $value*100;
  opacity: $value;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
  filter: alpha(opacity=$IEValue);
}

// Sizing shortcuts
@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin square($size) {
  @include size($size, $size);
}

@mixin transition($trans) {
  -webkit-transition: $trans;
  -moz-transition: $trans;
  -o-transition: $trans;
  transition: $trans;
}

@mixin transform($trans) {
  -webkit-transform: $trans;
  -moz-transform: $trans;
  -o-transform: $trans;
  transform: $trans;
}


// INIT HW Acceleration
@mixin hw-accelerate() {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

// ANTI PAGE FLICKER
@mixin anti-flicker {
  -webkit-perspective: 1000;
  backface-visibility:hidden;
  -webkit-backface-visibility:hidden;
  -moz-backface-visibility:hidden;
  -ms-backface-visibility:hidden;
}

// MEDIA QUERIES
@mixin bp($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin bp-max($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.1.
//
//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - 1px, null);
}

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}


@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}

%font-large {
  font-size: rem(15);
  line-height: rem(25);

  @include bp(lg) {
    font-size: rem(22);
    line-height: rem(36);
  }
}

%font-regular {
  font-size: rem(13);
  line-height: rem(25);

  @include bp(lg) {
    font-size: rem(18);
    line-height: rem(30);
  }
}

%font-small {
  font-size: rem(11);
  line-height: rem(12);

  @include bp(lg) {
    font-size: rem(15);
    line-height: rem(21);
  }
}
