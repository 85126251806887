.breadcrumbs {
  //padding: 0 14px;
  margin-bottom: $spacing-xl;

  &__items {
    list-style: none;
    display: flex;

    li {
      color: $grey;

      + li {
        &::before {
          content: '/';
          display: inline-block;
          margin: 0 0.4rem;
        }
      }
    }

    a {
      color: inherit;
      text-transform: uppercase;
      text-decoration: none;
      font-size: rem(12);

      &.active {
        font-weight: 500;
      }
    }
  }

  .page-header &, .hero & {
    margin-bottom: 0;

    a {
      color: $blue;
    }
  }

  .page-header--noImage & {
    a {
      color: $grey;
    }
  }
}
