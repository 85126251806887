.dove-siamo {
  font-size: rem(16);

  @include bp(sm) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__title {
    color: $blue;
    font-size: rem(28);
    font-weight: normal;
    margin-bottom: 1rem;

    @include bp(sm) {
      font-size: rem(35);
    }
  }

  p {
    margin: 5px 0;
  }

  .city {
    display: inline-block;
    color: $light-blue;
    text-transform: uppercase;
    font-size: rem(12);
    vertical-align: middle;
    margin-right: 10px;
  }

  .sedi {
    th {
      color: $light-blue;
      text-transform: uppercase;
      font-size: rem(12);
      font-weight: normal;
      text-align: left;
      padding: 2px 10px 2px 0;
    }

    td {
      padding: 2px 0;
    }
  }

  #map {
    width: 100%;
    margin-top: 1rem;
    height: 400px;

    @include bp(sm) {
      margin-top: 3rem;
    }

    @include bp(lg) {
      width: 50vw;
      height: auto;
      flex-grow: 999;
      margin-left: -50px;
    }

    @include bp(xl) {
      //height: 680px;
      margin-left: -110px;
    }
  }
}
