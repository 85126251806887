.page-header {
  position: relative;

  &__txt {
    @include bp(sm) {
      padding-top: 24px;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .container {
      @include bp(sm) {
        padding-left: 15px;
        padding-right: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        & > .page-title {
          flex-grow: 1;
        }
      }
    }
  }

  &__img {
    display: block;

    img {
      display: block;
    }
  }

  &__headings {
    padding-top: 2rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include bp(sm) {
      padding-top: 0;
      padding-bottom: 44px;
    }
  }

  .page-title {
    max-width: 630px;
    color: $blue;
    font-size: rem(30);

    @include bp(sm) {
      margin-top: 12px;
      margin-bottom: 12px;
      font-size: rem(40);
    }
  }

  .page-subtitle {
    color: $blue;
    font-size: rem(25);
    font-weight: 300;
  }

  .tag {
    align-self: flex-start;
  }

  &--noImage {
    @include bp(sm) {
      margin-bottom: 3rem;
    }

    .page-header__txt {
      position: relative !important;
      padding-top: 0;
    }

    .page-title {
      font-size: rem(40);
      max-width: none;

      @include bp(sm) {
        font-size: rem(75);
      }
    }
  }
}
