.article__info {
  list-style: none;
  display: flex;


  > li {
    display: flex;
    align-items: center;
  }

  .info-icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  .info-label {
    font-size: rem(12);
    color: $light-blue;
  }

  svg {
    fill: $light-blue;
  }
}

.page-content {
  padding: 2rem 0 4rem 0;

  &__share {
    margin-top: 2rem;

    @include bp(lg) {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  p {
    font-size: rem(14);
    line-height: 1.6;
  }

  .container {
    padding-left: 24px;
    padding-right: 24px;

    @include bp(sm) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .article__info {

    > li {
      margin-right: 2rem;
    }

    .info-label {
      color: $grey;
    }

    svg {
      fill: $grey;
    }
  }

  .text-centered {
    max-width: 540px;
    margin: 2rem auto;
  }

  .image-text + .text-centered {
    margin-top: 0;
  }
}

.news-sticky {
  position: relative;
  padding-bottom: 2rem;

  @include bp(lg) {
    display: flex;
    margin: 2rem 0 4rem 0;
  }

  .article__title {
    font-weight: 300;
    color: $blue;
    font-size: rem(20);
    margin: 10px 0;

    @include bp(sm) {
      font-size: rem(35);
    }
  }

  .article__info {
    justify-content: flex-start;
    margin-top: 2rem;

    > li + li {
      margin-left: 38px;
    }

    @include bp(sm) {
      margin-bottom: 30px;
    }
  }

  .article__tag {
    @include bp(lg) {
      position: absolute;
      top: 2rem;
      left: 2rem;
      z-index: 99;
    }
  }

  .article__img {
    display: block;
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: 1rem;

    @include bp(sm) {
      margin-left: 0;
      margin-right: 0;
    }

    @include bp(lg) {
      margin-left: 0;
      margin-right: 40px;
      margin-bottom: 0;
      flex-shrink: 0;
      width: 550px;
    }

    @include bp(xl) {
      width: 730px;
      margin-right: 70px;
    }

    img {
      width: 100%;

      @include bp(lg) {
        border-radius: 10px;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }

  .article__txt {
    display: flex;
    flex-direction: column;
    font-size: rem(14);
    line-height: 1.6;
  }

  .button-wrapper {
    margin-top: 2rem;
  }
}
