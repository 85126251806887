.site-header {
  &__top {
    display: none;
    padding: 12px 0 18px 0;

    @include bp(lg) {
      display: block;
    }
  }

  .unipol-logo {
    margin-left: 10px;

    img {
      display: block;
    }
  }

  &__main {
    padding: 15px 0;
    display: flex;
    align-items: center;

    @include bp(sm) {
      padding: 20px 15px;
    }

    @include bp(lg) {
      padding: 15px 14px;
      overflow: hidden;
    }
  }

  .alfa-logo {
    svg {
      width: 37px;
      height: 27px;

      @include bp(sm) {
        width: 200px;
        height: 35px;
      }

      @include bp(lg) {
        width: 160px;
      }
    }
  }

  .ui-button--search {
    margin-right: 40px;

    @include bp(lg) {
      margin-right: 0;
    }
  }

  .link-support {
    margin: 0 25px;
    display: none;

    @include bp(lg) {
      display: block;
    }

    svg {
      fill: $dark-blue;
    }
  }

  .link-account {
    display: flex;
    align-items: center;
  }

  .nav-main__items {
    min-width: 0%;

    @include bp-max(sm) {
      display: none;
    }
  }
}

.user-menu {
  list-style: none;

  &-btn {
    @include square(20px);
    background: none;
    border: none;
    cursor: pointer;

    svg {
      fill: $dark-blue;
    }

    &.active {
      svg {
        fill : $light-blue;
      }
    }
  }

  > li {
    height: 20px;
  }

  &__submenu {
    list-style: none;
    position: absolute;
    top: 100%;
    right: 0;
    border-radius: 10px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    background: #ffffff;
    padding: 1rem 20px;
    z-index: z('navigation');

    > li {
      padding: .4rem 0;
    }

    a {
      font-size: rem(14);
      font-weight: 500;
      text-transform: uppercase;
      text-decoration: none;
      color: $light-blue;
    }
  }
}
