.slider-loghi {
  transform: translateY(-50%);

  @include bp-max(xs) {
    padding: 0;
    margin-bottom: -50px;
  }

  .swiper-container {
    padding: 0 0 1rem 0 !important;

    @include bp(sm) {
      padding: 0 15px 1rem 15px !important;
    }
  }

  &__items {
    list-style: none;
  }

  .swiper-slide {
    width: 85vw;

    @include bp(sm) {
      width: auto;
    }
  }

  &__item a {
    display: block;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 1rem 40px;
  }

  img {
    max-width: 100%;
  }

  .swiper-buttons-container {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    pointer-events: none;
    z-index: 1;

    >* {
      pointer-events: all;
    }
  }

  .swiper-button {
    @include square(36px);

    svg {
      position: relative;
      fill: #fff;
      z-index: 2;
      @include size(12px, 10px);
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      @include square(36px);
      background: $light-blue;
      border-radius: 50%;
      z-index: 1;
    }

    &::after {
      content: none;
    }

    &-prev {
      left: -25px;

      @include bp(xxl) {
        left: -40px;
      }

      svg {
        transform: rotate(180deg);
      }
    }

    &-next {
      right: -25px;

      @include bp(xxl) {
        right: -40px;
      }
    }
  }
}
