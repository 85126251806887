html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html {
  height: 100%;
  font-size: 17px;
}

body {
  background: #fff;
  color: $text-color;
  font-family: $font-rubik;

  &.nav-open,
  &.search-open,
  &.filters-open,
  &.modal-open {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4 {
  font-weight: 500;
}

img {
  max-width: 100vw;
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  word-wrap: normal;
}

.visually-hidden.focusable:active,
.visually-hidden.focusable:focus {
  position: static !important;
  clip: auto;
  overflow: visible;
  height: auto;
  width: auto;
}

main {
  @include bp(sm) {
    padding-top: 45px;
  }
}
