.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: .9;
  z-index: 9998;
}

.modal {
  height: 90vh;
  top: 5vh;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 9999;

  @include bp(sm) {
    height: auto;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.modal-dialog {
  margin: 0 auto;
  padding-bottom: 1rem;
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: rem(26);
  background: none;
  border: none;
  color: $light-blue;
  cursor: pointer;
}

.modal-content {
  width: 95vw;
  margin: 0 auto;
  position: relative;
  padding: 40px 1rem 1rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  @include bp(xl) {
    padding: 50px 55px 30px 55px;
    width: 1110px;
  }
}
