.nav-top {
  background: $light-blue;
  border-radius: 17px;
  display: flex;
  justify-content: flex-end;
  padding: 0 27px;
  height: 30px;
  overflow: visible;

  &__items {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include bp(lg) {
      flex-direction: row;
    }

    a {
      display: block;
      margin: 0 17px;
      font-size: rem(12);
      text-transform: uppercase;
      text-decoration: none;
      color: #fff;
      letter-spacing: 0.8px;
      line-height: 30px;
      transition: color 0.2s;
    }
  }
}

.nav-main__items {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 999;

  > li {
    margin: 0 17px;
  }

  a {
    display: inline-block;
    color: $dark-blue;
    font-size: rem(14);
    line-height: 24px;
    text-decoration: none;

    &.active {
      border-bottom: 2px solid $blue;
    }
  }
}

.mobile-nav {
  background: $blue;
  position: fixed;
  top: 57px;
  right: 0;
  bottom: 0;
  left: 0;
  height: calc(100vh - 57px);
  padding: 0 45px 45px 45px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  z-index: 9999;
  display: none;

  @include bp(sm) {
    top: 80px;
    height: calc(100vh - 80px);
  }

  .nav-open & {
    display: flex;
  }

  @include bp(lg) {
    display: none !important;
  }

  .nav-main__items {
    flex-grow: 999;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > li {
      margin: 0;
    }

    a {
      display: block;
      margin: 10px 0;
      font-size: rem(20);
      color: #fff;
    }
  }

  .nav-top__items {
    border-top: 1px solid #82aee3;
    padding-top: 1rem;
    align-items: flex-start;
    margin-bottom: 45px;

    a {
      margin: 6px 0;
      font-size: rem(16);
    }
  }

  .language-nav {
    list-style: none;

    .active {
      display: none;
    }

    a {
      display: block;
      @include square(45px);
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #fff;
      color: #fff;
      text-decoration: none;
      font-size: rem(20);
      text-transform: uppercase;
    }
  }

  .link-support {
    margin: 0;

    svg {
      @include square(33px);
      fill: #fff;
    }
  }

  .flex-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
