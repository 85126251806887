.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: $spacing-xl;
  padding-right: $spacing-xl;

  @include bp(sm) {
    padding-left: 0;
    padding-right: 0;
    max-width: 710px;
  }

  @include bp(lg) {
    max-width: 1010px;
  }

  @include bp(xl) {
    max-width: 1150px;
  }

  &--overflow-right {
    @include bp(lg) {
      max-width: none;
      width: calc(1010px + (100vw - 1010px)/2);
      margin-right: 0;
      padding-left: 14px;
      display: flex;
    }

    @include bp(xl) {
      width: calc(1150px + (100vw - 1150px)/2);
    }
  }
}

.layout-cols {
  @include bp(lg) {
    display: flex;

    > * {
      flex: 0 0 50%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .col {
    padding-top: 3rem;
  }

  .col--left {
    background: #fafafa;

    @include bp(lg) {
      padding-right: 50px;
    }

    @include bp(xl) {
      padding-right: 110px;
    }
  }

  .col--right {
    justify-content: flex-start;

    @include bp(lg) {
      padding-left: 50px;
    }

    @include bp(xl) {
      padding-left: 110px;
    }
  }

  .col--image {
    padding: 0;

    img {
      max-width: 100%;
    }
  }

  .col__container {
    padding-left: 24px;
    padding-right: 24px;

    @include bp(sm) {
      margin-left: auto;
      margin-right: auto;
      max-width: 710px;
      padding-left: 15px;
      padding-right: 15px;
    }

    @include bp(lg) {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
      width: 440px;
    }

    @include bp(xl) {
      width: 450px;
    }
  }
}
