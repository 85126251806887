// type
$basefont: 17px;
$base-line-height: 28px;

// colors
$light-blue: #1687ff;
$blue: #005bcb;
$dark-blue: #000c67;
$grey: #9e9e9e;
$light-grey: #7c7c7c;
$text-color: #151515;

// fonts
$font-rubik: 'Rubik', sans-serif;

// spacings
$spacing-xs: 4px;
$spacing-s: 8px;
$spacing-m: 12px;
$spacing-l: 16px;
$spacing-xl: 24px;
$spacing-xxl: 32px;
$spacing-uber: 64px;

// timing functions
$bounced-timing: cubic-bezier(.64, .57, .47, 1.29);
$back-timing: cubic-bezier(0.175, 0.885, 0.320, 1.275);
$ease-out-quint:cubic-bezier(0.230, 1.000, 0.320, 1.000);
$ease-in-quint:cubic-bezier(0.755, 0.050, 0.855, 0.060);

// breakpoints
$grid-breakpoints: (xxs: 0,
  xs: 480px,
  sm: 768px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1340px) !default;

// z-indexs
$z-layers: (bottomless-pit: -9999,
  default: 1,
  navigation: 2000,
  mobileNav: 2500,
  dropdown: 3000,
  overlay: 4000,
  modal: 4001,
  foreground: 9999) !default;
