.highlights {
  padding: 1rem 0 !important;

  @include bp(sm) {
    padding: 40px 0;
    background: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  &__items {
    list-style: none;

    .swiper-slide {
      width: 85vw;
      background: #f0f0f0;
    }

    @include bp(sm) {
      padding: 25px 0;
      display: flex;
    }

    > li {
      text-align: center;
      padding: $spacing-xl 0;
      border-radius: 10px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

      @include bp(sm) {
        flex: 0 0 percentage(1/3);
        box-shadow: none;
        padding: 0;
      }

      span {
        display: block;
        text-transform: uppercase;

        &.value {
          color: $blue;
          font-size: rem(35);
          line-height: 1.3;
        }

        &.label {
          font-size: rem(14);
          line-height: 2;
          font-weight: 500;
        }
      }
    }
  }
}
