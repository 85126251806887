.prodotti-hp {
  &__wrapper {
    //display: flex;
    padding-top: 3rem;
    padding-bottom: 3rem;

    @include bp(sm) {
      display: block;
    }
  }

  &__title {
    font-size: rem(28);
    color: $blue;
    font-weight: 300;
    max-width: 65vw;
    margin: 2.5rem 0;

    @include bp(sm) {
      width: auto;
      font-size: rem(35);
      margin: 0 0 35px 0;
    }
  }

  &__slider {
    padding: 1rem 0 !important;

    @include bp(sm) {
      overflow: visible !important;
      padding: 0;
    }

    .swiper-slide {
      width: 85vw;
    }
  }

  &__items {
    list-style: none;
    //display: flex;
    //padding: 20px 0;
  }

  &__item {
    padding: 28px 22px 32px 24px;
    border-radius: 10px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;


    @include bp(sm) {
      flex: 1 1 percentage(1/3);
      max-width: 255px;
      height: auto !important;

      + .prodotti-hp__item {
        margin-left: 30px;
      }
    }

    .title {
      color: $blue;
      font-size: rem(20);
      display: block;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }

  &__link {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .button-wrapper {
    margin-top: 40px;
  }
}
