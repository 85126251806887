.supporto-categorie {
  &__wrapper {
    @extend .highlights;
    background: none;
    box-shadow: none;
    transform: translateY(-50%);
  }

  &__items {
    list-style: none;

    @include bp(sm) {
      display: flex;
    }

    .swiper-slide {
      width: 85vw;
    }
  }

  &__item {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    height: auto !important;

    @include bp(sm) {
      flex: 0 0 calc(25% - 24px);
      margin: 0 12px;
    }


    > a {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      padding: 25px 3rem;
      text-align: center;
      color: #aeaeae;
      font-size: rem(20);
      font-weight: 500;
      text-transform: uppercase;
      text-decoration: none;
      line-height: 1.4;

      &.active {
        color: $blue;
      }
    }
  }

  .faq {
    padding-top: 0;
  }
}
