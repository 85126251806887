.product-table {
  margin-top: 4rem;

  &__headings {
    @include bp(sm) {
      width: 970px;
      padding-left: 27px;
      display: flex;
      margin-bottom: 1rem;
    }

    @include bp(xl) {
      width: 1070px;
    }
  }

  .product-name {
    color: $blue;
    font-weight: normal;
    font-size: rem(28);
    margin-bottom: 1rem;

    @include bp(sm) {
      flex: 1;
      font-size: rem(35);
      margin-bottom: 0;
    }
  }

  .product-row {
    display: flex;
    align-items: center;

    &.open {
      .product-feature__description {
        display: block;
      }

      .product-feature__toggler {
        .line2 {
          display: none;
        }
      }
    }

    + .product-name {
      margin-top: 3rem;

      @include bp(sm) {
        margin-top: 0;
      }
    }
  }

  .product-feature {
    flex-grow: 1;
    padding: 10px 15px;

    @include bp(sm) {
      width: 970px;
      padding-top: 25px;
      padding-bottom: 25px;
      padding-left: 27px;
    }

    @include bp(lg) {
      width: 1070px;
    }

    &.odd {
      background: #f0f0f0;
      border-radius: 10px;
    }

    &__list {
      @include bp(sm) {
        display: flex;
      }
    }

    &__title {
      flex: 1;
      color: $dark-blue;
      font-size: rem(14);
      text-transform: uppercase;
      font-weight: 500;
    }

    &__description {
      display: none;
      font-size: rem(16);
      margin-top: 1rem;
      line-height: 1.75;
      max-width: 720px;

      @include bp(sm) {
        margin-top: 30px;
      }
    }

    &__toggler {
      align-self: flex-start;
      margin-right: 10px;
      margin-top: 8px;
      background: none;
      border: none;
      cursor: pointer;
      @include square(24px);

      @include bp(sm) {
        margin-top: 22px;
        margin-right: 0;
        margin-left: 16px;
      }
    }
  }
}

.product-details {
  padding: 0 0 4rem 0;

  &__readmore {
    margin-top: 60px;
    display: flex;
    align-items: center;
    font-size: rem(16);
  }

  .button {
    margin-left: 1.8rem;
  }

  &__title {
    color: $blue;
    font-weight: normal;
    margin-bottom: .8rem;
    font-size: rem(28);

    @include bp(sm) {
      font-size: rem(35);
    }
  }

  &__subtitle {
    color: $blue;
    font-size: rem(20);
    margin-bottom: 2rem;
  }

  p {
    max-width: 730px;
    font-size: rem(16);
    line-height: 1.75;
  }
}
