.site-footer {
  color: $light-grey;
  padding-left: 20px;
  padding-right: 20px;

  @include bp(sm) {
    margin-top: 80px;
  }

  .container {
    padding-left: 0;
    padding-right: 0;

    @include bp(lg) {
      padding-left: 14px;
      padding-right: 14px;
    }
  }

  &__top {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    flex-direction: column;
    padding-top: 45px;

    @include bp(lg) {
      flex-direction: row;
      padding-top: 0;
    }
  }

  .alfa-logo {
    padding-bottom: 20px;

    @include bp(lg) {
      padding-top: 10px;
      padding-bottom: 24px;
    }

    svg {
      width: 178px;
      height: 31px;

      @include bp(lg) {
        width: 201px;
        height: 34px;
      }
    }
  }

  .unipol-logo {
    padding-bottom: 18px;

    img {
      display: block;
      width: 113px;
    }
  }

  .corporate-block {
    order: 2;
    line-height: 18px;

    @include bp(lg) {
      max-width: 255px;
      margin-right: 30px;
      line-height: 14px;
    }

    p {
      margin: 4px 0;
    }

    &__logo {
      display: block;
    }

    + .corporate-block {
      margin-top: 50px;

      @include bp(lg) {
        margin-top: 0;
      }
    }
  }

  &__top-menu {
    order: 0;
    display: flex;
    justify-content: center;
    list-style: none;
    margin-bottom: 45px;

    @include bp(lg) {
      order: 3;
      margin-left: auto;
      margin-right: 40px;
      margin-bottom: 0;
    }

    @include bp(md) {
      margin-top: 1rem;
    }

    > li {
      margin: 0 15px;
    }

    a {
      color: $dark-blue;
      font-weight: 500;
      font-size: rem(14);
      text-decoration: none;
    }
  }

  .linkedin-icon {
    display: block;
    margin-top: 1rem;
  }

  .btn-gotop {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    
    @include bp(lg) {
      margin-top: 7px;
      position: static;
      transform: none;
      order: 4;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-direction: column;
    margin-top: 40px;
    border-top: 1px solid #dbdbdb;
    padding-top: 40px;

    @include bp(lg) {
      border: none;
      flex-direction: row;
      align-items: center;
      margin-top: 60px;
      padding-top: 0;
    }

    .legal {
      max-width: 200px;
      line-height: 1.5;

      @include bp(lg) {
        max-width: none;
        line-height: 24px;
      }
    }

    &-menu {
      list-style: none;
      margin-top: 30px;

      @include bp(lg) {
        display: flex;
        align-items: center;
        margin-top: 0;

        li + li {
          margin-left: 40px;
        }
      }

      a {
        display: block;
        text-decoration: none;
        text-transform: uppercase;
        color: inherit;
        letter-spacing: 2px;
        margin: 12px 0;
      }
    }
  }
}
