.ae-wizard {
  &__header {
    text-align: center;
    margin-bottom: 1rem;

    span {
      display: block;
    }
  }

  &__title {
    color: $blue;
    font-weight: 500;
    font-size: rem(20);
    margin-bottom: 10px;
  }

  &__subtitle {
    font-size: rem(16);
  }

  &__form {
    .form-item--checkbox {
      background: #f0f0f0;
      border-radius: 10px;
      padding: 25px 1rem;

      .label__title {
        display: block;
        color: $blue;
        font-size: rem(20);
        font-weight: 500;
      }

      .label__caption {
        font-size: rem(16);
      }
    }

    [type="checkbox"]:checked+label,
    [type="checkbox"]:not(:checked)+label {
      padding-left: 40px;
    }

    [type="checkbox"]:checked+label:before,
    [type="checkbox"]:not(:checked)+label:before {
      top: 50%;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
      border-radius: 3px;
      border: 2px solid #979797;
    }

    [type="checkbox"]:checked+label:after {
      @include square(18px);
      top: 50%;
      transform: translateY(-50%);
      border: 1px solid $light-blue;
      border-radius: 3px;
    }

    .form-group--checkboxes {
      @include bp(sm) {
        display: flex;
        flex-wrap: wrap;
      }

      .form-item {
        margin: 10px 0;
      }

      > * {
        flex: 1 0 50%;
        padding: 0 14px;
      }
    }

    .form-item--submit {
      display: flex;
      justify-content: center;
    }
  }

  &__wrapper {
    @include bp(sm) {
      display: flex;
    }
  }

  &__selected {
    @include bp(lg) {
      flex-basis: 350px;
    }
  }

  &__selected-item {
    display: block;
    margin-bottom: 10px;
    background: #f0f0f0;
    padding: 1rem 26px;
    color: $blue;
    font-weight: 500;
    text-decoration: none;
    border-radius: 10px;
    text-transform: uppercase;
  }

  &__detail {
    margin-top: 2rem;

    @include bp(sm) {
      margin-top: 0;
      margin-left: 70px;
      border-left: 1px solid #e9e9e8;
      padding-left: 2rem;
    }

    > .title {
      color: #aeaeae;
      display: block;
      text-transform: uppercase;
      font-size: rem(16);
      margin-bottom: 1rem;
    }
  }

  .product-detail {
    background: #f0f0f0;
    border-radius: 10px;
    padding: 30px 25px;

    .product-name {
      display: block;
      font-size: rem(35);
      color: $blue;

      margin-bottom: 2rem;
    }
  }

  .product-features {
    list-style: none;
    margin-bottom: 2rem;

    > li {
      display: flex;
      align-items: flex-start;

      + li {
        margin-top: 2rem;
      }

      svg {
        flex-shrink: 0;
        display: none;

        @include bp(sm) {
          display: block;
        }
      }

      .product-feature {
        @include bp(sm) {
          margin-left: 15px;
        }
      }
    }

    .feature__title {
      display: block;
      color: $blue;
      font-size: rem(14);
      font-weight: 500;

      @include bp(sm) {
        font-size: rem(20);
      }
    }

    .feature__caption {
      font-size: rem(16);
    }
  }
}
