.section-intro {

  @include bp-max(xs) {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;

    .breadcrumbs {
      display: none !important;
    }

    .block-image {
      order: 0;
      margin-bottom: 1rem;
    }

    .block-text {
      order: 1;
      padding: 0 30px;
    }
  }

  .block-image {
    flex-shrink: 0;
  }
}
