.tabs {
  list-style: none;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  align-items: flex-end;
  flex-wrap: wrap;

  @include bp(sm) {
    display: inline-flex;
  }

  @include bp(lg) {
    overflow-x: hidden;
  }

  @include bp(sm) {
    li {
      margin-bottom: $spacing-xl;

      + li {
        margin-left: $spacing-l;
      }
    }
  }

  a {
    position: relative;
    display: block;
    text-decoration: none;
    font-weight: 300;
    font-size: rem(30);
    line-height: 1.89;
    opacity: 0.3;
    color: #005bcb;

    @include bp(sm) {
      font-size: rem(35);
      font-weight: bold;
    }

    &[aria-selected] {
      opacity: 1;
      font-size: rem(40);
      line-height: 1.08;

      @include bp(sm) {
        font-size: rem(60);
      }
    }
  }
}

.tab-section {
  @include bp(sm) {
    padding-right: 74px;
  }

  p {
    line-height: 1.75;

    + p {
      margin-top: 40px;
    }
  }
}

.tabs--faq {
  list-style: none;

  .tab-item {
    > a {
      color: $grey;
      font-size: rem(14);
      font-weight: 500;
      text-transform: uppercase;
      text-decoration: none;

      &[aria-selected] {
        color: $light-blue;
      }
    }

    + .tab-item {
      margin-top: 25px;
    }
  }
}

.tabs--products {
  @extend .tabs--faq;
}

.tabbed--faq {
  .tab-wrapper {
    @include bp-max(xs) {
      display: none !important;
    }
  }

  .tab-title {
    color: $blue;
    font-size: rem(20);
    margin-bottom: 1rem;
  }

  .tab-section {
    font-size: rem(16);
    margin-bottom: 2rem;

    @include bp(sm) {
      margin-bottom: 0;
    }
  }

  .tab-index__container {
    @include bp(sm) {
      display: none !important;
    }
  }

  .tab-index {
    position: sticky;
    top: 2rem;
    margin-right: -24px;
    padding-top: 5px;

    &__item {
      display: block;
      background: $light-blue;
      height: 3px;
      width: 20px;
      margin-bottom: 5px;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      margin-left: 10px;
      transition: all 0.2s;
      //transform: translateX(10px);

      &.active {
        //transform: translateX(0);
        width: 30px;
        height: 5px;
        margin-left: 0;
      }
    }
  }
}

.tabbed--hasProgressBar {
  &__container {
    position: relative;
    padding: 20px 0 0 0;
  }

  @include bp(sm) {
    display: flex;
    align-items: flex-start;

    &__container {
      position: relative;
      order: 2;
      padding: 10px 32px 10px 0;
      margin-top: 90px;
    }

    .tabbed--faq__content {
      flex-grow: 999;
    }
  }

  .tabbed__progressBar {
    position: absolute;
    top: 0;
    width: 100%;
    height: 8px;
    border: 1px solid #aeaeae;
    border-radius: 24px;

    > .track {
      display: block;
      position: absolute;
      left: -1px;
      top: -1px;
      height: 8px;
      border-radius: 24px;
      background: $light-blue;
      transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    @include bp(sm) {
      right: 0;
      bottom: 0;
      width: 8px;
      height: 100%;

      > .track {
        width: 8px;
      }
    }
  }
}

.tabs--progressBar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;

  @include bp-max(xs) {
    > li {
      text-align: center;
      flex: 1 0 percentage(1/3);
    }

    a {
      display: block;
    }

    .tab-item + .tab-item {
      margin-top: 0;
    }
  }

  @include bp(sm) {
    display: block;

    > li {
      text-align: right;
      max-width: 120px;
    }
  }
}
