.skip-link:focus {
  display: block;
  width: 100%;
  text-align: center;
  font-size: rem(14);
  color: $blue;
  background: #EDF2F3;
  padding: 0.5rem 0;
}

.flex-wrapper {
  @include bp(lg) {
    display: flex;
    flex-wrap: wrap;

    &.col2 {
      >* {
        flex: 0 0 percentage(1/2);
        max-width: percentage(1/2);

        &:first-child {
          padding-right: 1rem;
        }

        &:last-child {
          padding-left: 1rem;
        }
      }
    }

    &.valign-bottom {
      align-items: flex-end;
    }

    &.valign-top {
      align-items: flex-start;
    }

    &.align-right {
      justify-content: flex-end;
      text-align: left;
    }
  }
}

.tag {
  display: inline-block;
  background: #f0f0f0;
  border-radius: 15px;
  color: $light-blue;
  text-transform: uppercase;
  font-size: rem(12);
  line-height: 22px;
  padding: 0 12px;
  text-decoration: none;

  &.active {
    background: $light-blue;
    color: #fff;
  }

  &--icon {
    display: inline-flex;
    align-items: center;

    svg {
      fill: $light-blue;
      margin-right: 4px;
    }
  }
}

.play-video {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    @include square(36px);
    background: url('../images/layout/play-video.svg') 50% 50%;
    background-size: 36px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.tag-list {
  list-style: none;
  display: flex;

  > li {
    flex-shrink: 0;
  }

  > li + li {
    margin-left: 10px;
  }

  .tag-reset {
    font-size: rem(10);
    color: #aeaeae;
    text-transform: uppercase;
    text-decoration: none;
  }
}

.bg--blue {
  background: $blue;
}

.bg--white {
  background: #fff;
}

.overflow-top {
  @include bp(sm) {
    transform: translateY(-50%);
  }

  + .image-text--overflow {
    @include bp(lg) {
      padding-top: 0;
      margin-top: -157px;
    }
  }
}

.page-wrapper {
  padding: $spacing-xl 0 0;
}

.default-wrapper {
  padding: $spacing-xl 0;
}

.main-wrapper {
  padding: $spacing-uber 0;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.block-left {
  @include bp(lg) {
    max-width: 448px;
  }
}

.valign-top {
  align-items: flex-start !important;
}

// Responsive utilities
.hidden-xs {
  @include bp-max(xs) {
    display: none !important;
  }
}

.hidden-sm {
  @include bp-max(sm) {
    display: none !important;
  }
}

.visible-xs {
  @include bp(sm) {
    display: none !important;
  }
}

.hidden-lg {
  @include bp(lg) {
    display: none !important;
  }
}
