.app-list {
  background: #fff;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.link-store {
  display: flex;

  svg {
    @include square(15px);
    fill: $light-blue;
    margin-right: 10px;
  }
}
